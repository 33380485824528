import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Header } from '../../components/header';
import { Ticker } from '../../components/ticker';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default class Page extends React.Component {
  constructor () {
    super();
    this.state = {
      open: false,
      colophon: false
    };
  }

  get className () {
    return classNames({
      'about': true,
      'about__colophon--open': this.state.colophon
    });
  }

  get website () {
    return this.props.pageContext.website;
  }

  get about () {
    return this.props.pageContext.about;
  }

  toggle = () => {
    this.setState({ colophon: !this.state.colophon });
  }

  componentDidMount () {
    this.link = this.refs.content.querySelector('a');
    this.link && this.link.addEventListener('click', this.openAbout);
  }

  componentWillUnmount () {
    this.link && this.link.removeEventListener('click', this.openAbout);
  }

  openAbout = (e) => {
    e.preventDefault();
    this.componentWillUnmount();
    this.link = false;
    this.setState({ open: true });
  }

  render = () => (
    <>
     <Helmet>
        <title>{ this.website.title }</title>
        <meta name="description" content={this.website.description} />
        <meta property="og:image" content={this.website.socialMediaImage.file.url} />
      </Helmet>
      <Header website={this.website} />
      <main className={this.className}>
        <Ticker>{ this.about.ticker }</Ticker>
        <section className="about__intro about__container about__flex">
          <h2 className="about__intro__left">{ documentToReactComponents(this.about.mainLeftColumn) }</h2>
          <div className="about__intro__right" ref="content">
            { !this.state.open && documentToReactComponents(this.about.mainRightColumnShort) }
            { this.state.open && documentToReactComponents(this.about.mainRightColumn) }
          </div>
        </section>

        <section className="about__container about__teachers">
          <h3 className="about__teachers__title">{ this.about.teachersTitle }</h3>
          <div className="about__teachers__columns about__flex">
            <div>{ documentToReactComponents(this.about.teachers1) }</div>
            <div>{ documentToReactComponents(this.about.teachers2) }</div>
            <div>{ documentToReactComponents(this.about.teachers3) }</div>
            <div>{ documentToReactComponents(this.about.teachers4) }</div>
            <div>{ documentToReactComponents(this.about.teachers5) }</div>
          </div>
        </section>

        <section className="about__flex about__photos">
          { this.about.images.map(image => (
            <div className="about__photo">
              { image.file?.url && <img src={image.file?.url} alt={image.description} />}
              <label>{image.description}</label>
            </div>
          )) }
        </section>

        <section className="about__container about__colophon">
          <h3 className="about__colophon__title" onClick={this.toggle}>{ this.about.colophonTitle }<span/></h3>
          <div className="about__colophon__columns about__flex">
            <div>{ documentToReactComponents(this.about.colophonLeft) }</div>
            <div>{ documentToReactComponents(this.about.colophonRight) }</div>
          </div>
        </section>

        <section className="about__partners">
          <h3 className="about__partners__title">{ this.about.partnersTitle }</h3>
          <div className="about__partners__grid__wrapper">
            <div className="about__partners__grid">
              { this.about.partners.map(partner => (
                <a href={partner.link} target="_blank" className="about__partner">
                  { partner.logo?.file?.url && <img src={partner.logo.file.url} />}  
                </a>
              )) }
            </div>
          </div>
        </section>
      </main>
    </>
  )
}